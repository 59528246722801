<template>
    <v-app id="reset-pass">
        <v-card class="elevation-12" :loading="loading"
            loader-height="6" :disabled="loading">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click.stop.prevent="$router.push({name: 'login'})">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Recuperação de Senha</v-toolbar-title>
                <v-spacer />
            </v-toolbar>
            <v-card-text>
                <v-form v-if="step === 0" class="p-l-50 p-r-50">
                    <v-row justify="center">
                        <p class="body-1">Precisamos confirmar o seu usuário antes de autorizar esta ação. Por favor informe os dados a seguir.<br>Após confirmado, você receberá um código pin de autorização no seu celular por SMS.</p>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-text-field id="phone" v-model="usuario.phone" v-maska="'(##) # ####-####'" label="Celular" required />
                        </v-col>
                        <!-- <v-col cols="12" sm="6">
                            <v-text-field v-model="usuario.mail" type="mail" label="E-mail"
                                required />
                        </v-col> -->
                    </v-row>
                    <v-row justify="center">
                        <v-btn color="primary" type="submit"
                            @click.prevent.stop="requestPin">Verificar usuário</v-btn>
                    </v-row>
                </v-form>

                <v-form v-else class="p-l-50 p-r-50 text-center">
                    <p class="body-1 d-block">Informe o PIN recebido e a nova senha.</p>
                    <p class="body-2">Caso não tiver recebido o SMS, você pode solicitar novamente após 1 minuto clicando abaixo:</p>
                    <v-btn color="primary" :disabled="waitNewPin" small
                            @click.prevent.stop="requestPin">{{ waitNewPin ? timeWait : 'Solicitar novo PIN' }}</v-btn>
                    <v-row class="mt-10" justify="center">
                        <v-col cols="12" sm="5" md="3" lg="2">
                            <v-text-field id="phone" v-model="usuario.pin" v-maska="'####'" label="PIN" required autocomplete="one-time-code" @keyup="checkPin" />
                        </v-col>
                    </v-row>
                    <v-row v-if="pinChecked" justify="center">
                        <v-col class="input-icon" cols="10" sm="3">
                            <v-text-field v-model="usuario.password" label="Senha nova"
                                :type="(viewPass)?'text':'password'" counter required />
                                <v-icon v-if="viewPass" class="view-pass"
                                    @click="viewPass = false">visibility</v-icon>
                                <v-icon v-else class="view-pass"
                                     @click="viewPass = true">visibility_off</v-icon>
                        </v-col>
                        <!-- <v-col class="input-icon" cols="10" sm="3">
                            <v-text-field v-model="usuario.verifyPass"
                                label="Confirmar senha"
                                :type="(viewPass)?'text':'password'"
                                counter required autocomplete="off" />
                                <v-icon v-if="viewPass" class="view-pass"
                                    @click="viewPass = false">visibility</v-icon>
                                <v-icon v-else class="view-pass"
                                    @click="viewPass = true">visibility_off</v-icon>
                        </v-col> -->
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-btn color="primary" :disabled="!pinChecked" type="submit"
                                @click.prevent.stop="alterPassword">Alterar senha</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>

        <Snackbar :snackbar-flag="snackbar.activeFlag" :permanent="snackbar.permanentFlag"
            :error="snackbar.errorFlag" :color="snackbar.color"
            :hide-close-buttom="snackbar.isConfirmMode" justify-actions="end">
            <template v-slot:message>{{ snackbar.text }}</template>
            <template v-slot:actions>
                <v-btn v-if="snackbar.isConfirmMode" color="matchpal"
                    @click.prevent="snackbar.confirm = true; snackbar.activeFlag = 0">OK
                </v-btn>
            </template>
        </Snackbar>
    </v-app>
</template>

<script>
import Utils from '@components/Utils/Utils.vue'
import { maska } from 'maska'


export default {
    name: "RecuperarSenha",
    components: {
        Snackbar: () => import("@components/Snackbar.vue")
    },
    directives: { maska },
    mixins: [ Utils ],
    data: () => ({
        loading: false,
        step: 0,
        usuario: {
            phone: '',
            mail: '',
            pin: '',
            password: '',
            verifyPass: '',
        },
        timeWait: 59,
        waitNewPin: true,
        pinChecked: false,
        viewPass: false,
    }),
    mounted() {
        this.inicializeReCaptcha()
    },
    destroyed() {
        this.destroyReCaptcha()
    },
    methods: {
        checkInputs: function() {
            if(this.step === 0) {
                if(this.unformatPhone(this.usuario.phone).length !== 13) {
                    this.notify('Digite um celular válido', true)
                    return false
                }
            } else if(this.step === 1) {
                if(this.usuario.pin < 4) {
                    this.notify('Informe o PIN recebido', true)
                    return false
                }
                if(this.usuario.password.length < 8) {
                    this.usuario.password = ''
                    this.usuario.verifyPass = ''
                    this.notify('A senha precisa ter no mínimo 8 caracters', true)
                    return false
                }
            }
            return true
        },
        requestPin(event) {
            this.waitNewPin = true

            if (this.checkInputs()) {
                grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY)
                    .then((tokenCaptcha) => {
                        this.$http({
                            method: 'post',
                            url: '/usuario/recuperarSenha',
                            data: {
                                phone: this.unformatPhone(this.usuario.phone),
                                grecaptcha: tokenCaptcha,
                            },
                        }).then(response => {
                            if (response.data.status === 'success') {
                                this.step = 1
                                this.usuario.pin = ''
                                this.timeWait = 59
                                this.counter()
                            } else {
                                this.notify(response.data.message, true)
                            }
                        }).catch(error => {
                            return this.notify('Tente novamente!', true)
                        })
                    })
            }
        },
        alterPassword(event) {
            if (this.checkInputs()) {
                grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY)
                    .then((tokenCaptcha) => {
                        this.$http({
                            method: 'post',
                            url: '/usuario/resetarSenhaPin',
                            data: {
                                phone: this.unformatPhone(this.usuario.phone),
                                pin: this.usuario.pin,
                                password: this.usuario.password,
                                grecaptcha: tokenCaptcha,
                            },
                        }).then(response => {
                            if (response.data.status === 'success') {
                                this.confirm('Senha alterada com sucesso!', true).then(() =>
                                    this.$router.push({ path: '/login' })
                                )
                            } else {
                                this.notify(response.data.message, true)
                            }
                        }).catch(error => {
                            return this.notify('Tente novamente!', true)
                        })
                    })
            }
        },
        counter() {
            setTimeout(() => {
                if(this.timeWait > 0) {
                    this.timeWait--
                    this.counter()
                } else if(this.timeWait === 0)
                    this.waitNewPin = false
            }, 1000)
        },
        checkPin(event) {
            if(this.usuario.pin.length === 4)
                this.pinChecked = true
            else
                this.pinChecked = false
        }
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'

    *::v-deep
        #reset-pass
            .v-card__text
                padding: 16px 30px

        #phone
            text-align: center

        .input-icon
            position: relative

        .view-pass
                position: absolute
                top: 31px
                right: 22px
                cursor: pointer
</style>